/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($, undefined) {
	$.fn.matchBox = function () {
   	var Boxes = this,
   	tallest = 0,
				 height;
		 Boxes.each(function(i){
			 height = $(this).height();
			 if(height > tallest){
				 tallest = height;
			 }
		 });
		 Boxes.height(tallest);
	 };
 })(jQuery);

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

		minWidth: function(compare){
			return  compare <= window.innerWidth;
		},
		search: function(){
			$('.search-button').on('click', function(evt){
				evt.preventDefault();
				$('.search-box').show();
				setTimeout(function(){
					$('.search-box').addClass('active');
					$('body').addClass('active-search');
				}, 200);
			});

			$('.search-close').on('click', function(evt){
				$('.search-box').removeClass('active');
				$('body').removeClass('active-search');
				setTimeout(function(){
					$('.search-box').hide();
				}, 1000);

			});

		},
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

				if(!Sage.minWidth(991)){
					$('.dropdown-toggle').attr('data-toggle', 'dropdown');
					$('.dropdown-toggle').click(function(evt){
						evt.preventDefault();
						if($(this).parent('li').hasClass('open')){
							window.location = this.href;
						}
					});
				}

        if($('.js-date-in').length > 0){

          var d = new Date(),
						t = d.setDate(d.getDate()+1),
						inDate,
						outDate;

				var $In = $('.js-date-in').pickadate({
					min: true,
					format: 'mm/dd/yyyy',
					container: '#root-picker-outlet'
				});

				var $Out = $('.js-date-out').pickadate({
					min: d,
					format: 'mm/dd/yyyy',
					container: '#root-picker-outlet'
				});

				var pickerIn = $In.pickadate('picker'),
						pickerOut = $Out.pickadate('picker');

				pickerIn.on({
					set: function(date) {

						pickerOut.clear();

  				}
				});

				pickerOut.on({
					set: function(date) {

						if(date.select){
							// pickerIn.set('max', new Date(date.select));
						}
  				}
				});
				//testing
				$('.js-date-in').on('change', function(evt){

					inDate = $(this).val();

					d = new Date(Date.parse(inDate));
					d.setDate(d.getDate() +1);
					t = [d.getFullYear(),d.getMonth(),d.getDate()];

					$('.js-booking-in').val($(this).val());

					pickerOut.set('min', t);

				});

				$('.js-date-out').on('change', function(evt){

					outDate = $(this).val();
					$('.js-booking-out').val($(this).val());
				});

        }

				if(Sage.minWidth(990)){
					$('.js-book-now').hover(function(evt){

						$(this).addClass('active');
						$('.hover-target').addClass('active');
						$('.booking-collapse').addClass('in');

					});

					$('.hover-target').hover(function(evt){
						$('.js-book-now').removeClass('active');
						$('.booking-collapse').removeClass('in');
						$('.hover-target').removeClass('active');
					});
				}

				Sage.search();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

				$.getJSON('https://events.proximityhotel.com/wp-json/wp/v2/events', function(data){

					$.each(data, function(i){

						htmlText = this.content.rendered.match(/alt="[^"]*/g);
						if(htmlText){
						altInfo = htmlText[0].split('"');
						altInfo = altInfo[1];
						}
						else{
							altInfo = this.title.rendered;
						}




						var html = '<div class="slide"><a href="/happenings/#event/'+ this.id +'"><img src="' + this.featured_image_urls.full_size + '" alt="' + altInfo + '" >';
						html += '<h3>' + this.title.rendered + '</h3>';



						if(this.events_time && (this.events_time.event_repeating === 'Weekly')){
							html += '<h4>Every ' + this.events_time.event_repeating_week_day + '</h4>';
						} else if(this.events_time.event_date){
							html += '<span class="js-date" data-date="' + this.events_time.event_date + '"></span>';
						}

						if(this.events_time && this.events_time.event_location_details){
						  html += '<h5>' + this.events_time.event_location_details + '</h5>';
						}

						html += this.excerpt.rendered;
						html += '</a></div>';


						$(html).appendTo('.owl-carousel');
					});

				}).done(function(){

					$('.owl-carousel').owlCarousel({
						nav: true,
						navText: ['<span class="chevron-left"></span>','<span class="chevron-right"></span>'],
						dots: false,
						responsive: {
							0:{
            		items:1
        			},
        			780:{
            		items:3
        			},
						}
					});

					$('.js-date').each(function(i){
		      	var stamp = parseFloat($(this).attr('data-date')) + 86400,
		      	    day = moment.unix(stamp).format("dddd, MMMM Do");
		      	$(this).text(day);
					});

				});

        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
		'post_type_archive': {
			init: function(){
				$('.js-offer-card').matchBox();
			}
		}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
